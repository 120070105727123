import { GetStaticProps } from "next";
import { useEffect, useRef, VFC } from "react";
import { SEO } from "~/components";
import { useNavigation } from "~/hooks";
import { Home } from "~/layouts";
import { fetchNews, News } from "~/lib/server/microcms";

type Props = {
  news: News[];
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const response = await fetchNews(0, 3);
  return {
    props: {
      news: response
    },
    revalidate: 60
  };
};

const HomePage: VFC<Props> = ({ news }) => {
  const toggleColorRef = useRef<HTMLDivElement>(null);
  const { toggleColor } = useNavigation();
  const toggleColorState = () => {
    if (toggleColorRef.current) {
      const scrollY = window.scrollY || window.pageYOffset;
      if (scrollY > toggleColorRef.current.getBoundingClientRect().top) {
        toggleColor(true);
      } else {
        toggleColor(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleColorState);
    return () => {
      window.removeEventListener("scroll", toggleColorState);
    };
  }, []);
  return (
    <Home>
      <SEO />
      <Home.Hero />
      <div id="observable-line" ref={toggleColorRef} />
      <Home.About />
      <Home.OnlineShop />
      <Home.News news={news} />
    </Home>
  );
};

export default HomePage;
